import { HttpService } from '@core/http';
import { Injectable } from '@angular/core';
import { IChapterUserdata, IColumnUserdata, ICourseUserdata } from '@models/course-userdata';
import { IChapter } from '@models/chapter';
import { createProfileUrl } from '@utils/urlFactory';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  constructor(
    private client: HttpService<ICourseUserdata>,
  ) {}

  public getChapterData(chapter: IChapter) {
    return this.client.get<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
      ),
    );
  }

  public getColumnData(courseId: string, columnId: string) {
    return this.client.get<IColumnUserdata>(
      createProfileUrl(
        'userdata',
        'course', courseId,
        'column', columnId,
      ),
    );
  }

  public bookmarkChapter(chapter: IChapter) {
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'bookmark'
      ),
      null
    );
  }

  public unBookmarkChapter(chapter: IChapter) {
    return this.client.delete<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'bookmark'
      ),
    );
  }

  public bookmarkSection(chapter: IChapter, sectionId: string) {
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'section', sectionId,
        'bookmark'
      ),
      null
    );
  }

  public unBookmarkSection(chapter: IChapter, sectionId: string) {
    return this.client.delete<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'section', sectionId,
        'bookmark'
      ),
    );
  }
}
